import React from 'react'
import Layout from '../components/layout'
import monica_chandler from '../assets/images/monica_chandler.png'

class Friends extends React.Component {
  render() {

    return (
      <img src={monica_chandler} alt="" />
    )
  }
}

export default Friends
